import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [{
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path(.*)',
      component: (resolve) => require(['@/views/redirect'], resolve)
    }]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
	{
    path: '/register',
    component: (resolve) => require(['@/views/register'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [{
      path: 'index',
      component: (resolve) => require(['@/views/index'], resolve),
      name: 'Index',
      meta: { title: '首页', icon: 'dashboard', affix: true }
    }],
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
      path: 'profile',
      component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
      name: 'Profile',
      meta: { title: '个人中心', icon: 'user' }
    }]
  },
	{
    path: '/echarts',
    component: (resolve) => require(['@/views/components/Echarts'], resolve),
    hidden: true
  },
  {
    path: '/icons',
    component: (resolve) => require(['@/views/components/icons/index'], resolve),
    hidden: true
  },
]

// 动态路由匹配本地文件路径,非变量的文件路径越详细则动态路由加载的文件越少
export function routeMateFilePath(path) {
  let lastPathList = path.split("/")
  lastPathList.splice(0,1)
  let lastPath = lastPathList.join('/')   //views目录下剩余路径
  if(path.includes('business/')){
    let lastPathList2 = lastPath.split('/')
    lastPathList2.splice(0,1)
    let lastPath2 = lastPathList2.join('/')   //business目录下剩余路径
    // 业务目录下再细分
    if(path.includes("business/activity/")){
      if(lastPath2 == 'Index'){
        return (resolve) => require([`@/views/business/activity/Index`], resolve)
      }else if(lastPath2 == 'ActivityCategory'){
        return (resolve) => require([`@/views/business/activity/ActivityCategory`], resolve)
      }else if(lastPath2 == 'activitySet'){
        return (resolve) => require([`@/views/business/activity/activitySet`], resolve)
      }
    }else if(path.includes("business/address/")){
      return (resolve) => require([`@/views/business/address/${lastPath2}`], resolve)
    }else if(path.includes("business/agent/")){
      return (resolve) => require([`@/views/business/agent/${lastPath2}`], resolve)
    }else if(path.includes("business/advertise/")){
      return (resolve) => require([`@/views/business/advertise/${lastPath2}`], resolve)
    }else if(path.includes("business/bank/")){
      return (resolve) => require([`@/views/business/bank/${lastPath2}`], resolve)
    }else if(path.includes("business/category/")){
      if(lastPath2 == 'index'){
        return (resolve) => require([`@/views/business/category/index`], resolve)
      }else if(lastPath2 == 'catalogue'){
        return (resolve) => require([`@/views/business/category/catalogue`], resolve)
      }else if(lastPath2 == 'brandCatalog'){
        return (resolve) => require([`@/views/business/category/brandCatalog`], resolve)
      }else if(lastPath2 == 'shopCatelog'){
        return (resolve) => require([`@/views/business/category/shopCatelog`], resolve)
      }else if(lastPath2 == 'promotionCatelog'){
        return (resolve) => require([`@/views/business/category/promotionCatelog`], resolve)
      }else if(lastPath2 == 'mallCatelog'){
        return (resolve) => require([`@/views/business/category/mallCatelog`], resolve)
      }else if(lastPath2 == 'stockCatelog'){
        return (resolve) => require([`@/views/business/category/stockCatelog`], resolve)
      }else if(lastPath2 == 'advertCatelog'){
        return (resolve) => require([`@/views/business/category/advertCatelog`], resolve)
      }else if(lastPath2 == 'communityCatelog'){
        return (resolve) => require([`@/views/business/category/communityCatelog`], resolve)
      }else if(lastPath2 == 'homepageZone'){
        return (resolve) => require([`@/views/business/category/homepageZone`], resolve)
      }else{
        return (resolve) => require([`@/views/business/category/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/brand/")){
      if(lastPath2 == 'index'){
        return (resolve) => require([`@/views/business/brand/index`], resolve)
      }else if(lastPath2 == 'catalog/index'){
        return (resolve) => require([`@/views/business/brand/catalog/index`], resolve)
      }else if(lastPath2 == 'category/index'){
        return (resolve) => require([`@/views/business/brand/category/index`], resolve)
      }else{
        return (resolve) => require([`@/views/business/brand/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/corp/")){
      if(lastPath2 == 'Corp'){
        return (resolve) => require([`@/views/business/corp/Corp`], resolve)
      }else{
        return (resolve) => require([`@/views/business/corp/${lastPath2}`], resolve)
      }
    }else if(path.includes("business/coupon/")){
      if(lastPath2 == 'cashCoupon'){
        return (resolve) => require([`@/views/business/coupon/cashCoupon`], resolve)
      }else if(lastPath2 == 'cashCouponSet'){
        return (resolve) => require([`@/views/business/coupon/cashCouponSet`], resolve)
      }else if(lastPath2 == 'cashCouponRecord'){
        return (resolve) => require([`@/views/business/coupon/cashCouponRecord`], resolve)
      }else if(lastPath2 == 'cashCouponRule'){
        return (resolve) => require([`@/views/business/coupon/cashCouponRule`], resolve)
      }else if(lastPath2 == 'couponSettings'){
        return (resolve) => require([`@/views/business/coupon/couponSettings`], resolve)
      }else if(lastPath2 == 'index'){
        return (resolve) => require([`@/views/business/coupon/index`], resolve)
      }else if(lastPath2 == 'userecord'){
        return (resolve) => require([`@/views/business/coupon/userecord`], resolve)
      }else{
        return (resolve) => require([`@/views/business/coupon/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/crm/")){
      return (resolve) => require([`@/views/business/crm/${lastPath2}`], resolve)
    }else if(path.includes("business/customer/")){
      if(lastPath2 == 'customerManagement'){
        return (resolve) => require([`@/views/business/customer/customerManagement`], resolve)
      } else if(lastPath2 == 'index'){
        return (resolve) => require([`@/views/business/customer/index`], resolve)
      }else{
        return (resolve) => require([`@/views/business/customer/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/factory/")){
      return (resolve) => require([`@/views/business/factory/${lastPath2}`], resolve)
    }else if(path.includes("business/freight/")){
      if(lastPath2 == 'FreightFeeGeneralRuleSet'){
        return (resolve) => require([`@/views/business/freight/FreightFeeGeneralRuleSet`], resolve)
      }else if(lastPath2 == 'FreightFeeSpecificRuleSet'){
        return (resolve) => require([`@/views/business/freight/FreightFeeSpecificRuleSet`], resolve)
      }else if(lastPath2 == 'FreightFeeGeneralRule'){
        return (resolve) => require([`@/views/business/freight/FreightFeeGeneralRule`], resolve)
      }else if(lastPath2 == 'FreightFeeSpecificRule'){
        return (resolve) => require([`@/views/business/freight/FreightFeeSpecificRule`], resolve)
      }else if(lastPath2 == 'FreightSetting'){
        return (resolve) => require([`@/views/business/freight/FreightSetting`], resolve)
      }else{
        return (resolve) => require([`@/views/business/freight/${lastPath2}`], resolve)
      }
    }else if(path.includes("business/golf/")){
      return (resolve) => require([`@/views/business/golf/${lastPath2}`], resolve)
    }else if(path.includes("business/goods/")){
      if(lastPath2 == 'info/goodsedit'){
        return (resolve) => require([`@/views/business/goods/info/goodsedit`], resolve)
      }else if(lastPath2 == 'GoodsAreaShopControl'){
        return (resolve) => require([`@/views/business/goods/GoodsAreaShopControl`], resolve)
      }else if(lastPath2 == 'GoodsAreaShopQuantity'){
        return (resolve) => require([`@/views/business/goods/GoodsAreaShopQuantity`], resolve)
      }else if(lastPath2 == 'GoodsAreaShopSaleAllow'){
        return (resolve) => require([`@/views/business/goods/GoodsAreaShopSaleAllow`], resolve)
      }else if(lastPath2 == 'GoodsAreaShopSale'){
        return (resolve) => require([`@/views/business/goods/GoodsAreaShopSale`], resolve)
      }else if(lastPath2 == 'GoodsAreaShopSaleNotAllow'){
        return (resolve) => require([`@/views/business/goods/GoodsAreaShopSaleNotAllow`], resolve)
      }else if(lastPath2 == 'info/goodsShopEdit'){
        return (resolve) => require([`@/views/business/goods/info/goodsShopEdit`], resolve)
      }else if(lastPath2 == 'category/index'){
        return (resolve) => require([`@/views/business/goods/category/index`], resolve)
      }else if(lastPath2 == 'catalogue/index'){
        return (resolve) => require([`@/views/business/goods/catalogue/index`], resolve)
      }else if(lastPath2 == 'category/goodsCateSet'){
        return (resolve) => require([`@/views/business/goods/category/goodsCateSet`], resolve)
      }else if(lastPath2 == 'category/goodsCateSet'){
        return (resolve) => require([`@/views/business/goods/category/goodsCateSet`], resolve)
      }else if(lastPath2 == 'buygive/BuygiveSet'){
        return (resolve) => require([`@/views/business/goods/buygive/BuygiveSet`], resolve)
      }else if(lastPath2 == 'buygive/goodsBuygiveRule'){
        return (resolve) => require([`@/views/business/goods/buygive/goodsBuygiveRule`], resolve)
      }else if(lastPath2 == 'buygive/goodsBuygiveSet'){
        return (resolve) => require([`@/views/business/goods/buygive/goodsBuygiveSet`], resolve)
      }else if(lastPath2 == 'buygive/goodsBuygiveRecord'){
        return (resolve) => require([`@/views/business/goods/buygive/goodsBuygiveRecord`], resolve)
      }else if(lastPath2 == 'buygive/BuygiveRuleSet'){
        return (resolve) => require([`@/views/business/goods/buygive/BuygiveRuleSet`], resolve)
      }else if(lastPath2 == 'buygive/BuygiveRecordView'){
        return (resolve) => require([`@/views/business/goods/buygive/BuygiveRecordView`], resolve)
      }else if(lastPath2 == 'buygive/goodsBuygiveView'){
        return (resolve) => require([`@/views/business/goods/buygive/goodsBuygiveView`], resolve)
      }else if(lastPath2 == 'buygive/goodsBuygive'){
        return (resolve) => require([`@/views/business/goods/buygive/goodsBuygive`], resolve)
      }else if(lastPath2 == 'GoodsPricesView'){
        return (resolve) => require([`@/views/business/goods/GoodsPricesView`], resolve)
      }else if(lastPath2 == 'GoodsStoreView'){
        return (resolve) => require([`@/views/business/goods/GoodsStoreView`], resolve)
      }else if(lastPath2 == 'info/goodsPriceSet'){
        return (resolve) => require([`@/views/business/goods/info/goodsPriceSet`], resolve)
      }else{
        return (resolve) => require([`@/views/business/goods/${lastPath2}`], resolve)
      }
    }else if(path.includes("business/groupBooking/")){
      if(lastPath2 == 'GroupBookingGoodsSelect'){
        return (resolve) => require([`@/views/business/groupBooking/GroupBookingGoodsSelect`], resolve)
      }else if(lastPath2 == 'GroupBookingGoodsSet'){
        return (resolve) => require([`@/views/business/groupBooking/GroupBookingGoodsSet`], resolve)
      }else if(lastPath2 == 'GroupBookingJointSelect'){
        return (resolve) => require([`@/views/business/groupBooking/GroupBookingJointSelect`], resolve)
      }else{
        return (resolve) => require([`@/views/business/groupBooking/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/invoice/")){
      if(lastPath2 == 'orderInvoicing'){
        return (resolve) => require([`@/views/business/invoice/orderInvoicing`], resolve)
      }else if(lastPath2 == 'InvoiceUserInfo'){
        return (resolve) => require([`@/views/business/invoice/InvoiceUserInfo`], resolve)
      }else if(lastPath2 == 'Index'){
        return (resolve) => require([`@/views/business/invoice/Index`], resolve)
      }else if(lastPath2 == 'InvoiceDetail'){
        return (resolve) => require([`@/views/business/invoice/InvoiceDetail`], resolve)
      }else if(lastPath2 == 'IndexQuery'){
        return (resolve) => require([`@/views/business/invoice/IndexQuery`], resolve)
      }else if(lastPath2 == 'InvoiceDetailQuery'){
        return (resolve) => require([`@/views/business/invoice/InvoiceDetailQuery`], resolve)
      }else if(lastPath2 == 'InvoiceUserInfoQuery'){
        return (resolve) => require([`@/views/business/invoice/InvoiceUserInfoQuery`], resolve)
      }else{
        return (resolve) => require([`@/views/business/invoice/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/logistics/")){
      if(lastPath2 == 'delivery/orderSend'){
        return (resolve) => require([`@/views/business/logistics/delivery/orderSend`], resolve)
      }else if(lastPath2 == 'delivery/orderReceipt'){
        return (resolve) => require([`@/views/business/logistics/delivery/orderReceipt`], resolve)
      }else if(lastPath2 == 'delivery/orderReturn'){
        return (resolve) => require([`@/views/business/logistics/delivery/orderReturn`], resolve)
      }else if(lastPath2 == 'receiveplan/index'){
        return (resolve) => require([`@/views/business/logistics/receiveplan/index`], resolve)
      }else{
        return (resolve) => require([`@/views/business/logistics/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/lottery/")){
      if(lastPath2 == 'UserLotteryTicket'){
        return (resolve) => require([`@/views/business/lottery/UserLotteryTicket`], resolve)
      }else if(lastPath2 == 'UserLotteryItem'){
        return (resolve) => require([`@/views/business/lottery/UserLotteryItem`], resolve)
      }else if(lastPath2 == 'UserLotteryDraw'){
        return (resolve) => require([`@/views/business/lottery/UserLotteryDraw`], resolve)
      }else if(lastPath2 == 'UserLotteryResult'){
        return (resolve) => require([`@/views/business/lottery/UserLotteryResult`], resolve)
      }else if(lastPath2 == 'UserLotteryReceive'){
        return (resolve) => require([`@/views/business/lottery/UserLotteryReceive`], resolve)
      }else if(lastPath2 == 'LotteryTicketSet'){
        return (resolve) => require([`@/views/business/lottery/LotteryTicketSet`], resolve)
      }else if(lastPath2 == 'LotteryTicket'){
        return (resolve) => require([`@/views/business/lottery/LotteryTicket`], resolve)
      }else if(lastPath2 == 'LotteryItemSet'){
        return (resolve) => require([`@/views/business/lottery/LotteryItemSet`], resolve)
      }else if(lastPath2 == 'LotteryItem'){
        return (resolve) => require([`@/views/business/lottery/LotteryItem`], resolve)
      }else if(lastPath2 == 'LotteryObjectSet'){
        return (resolve) => require([`@/views/business/lottery/LotteryObjectSet`], resolve)
      }else if(lastPath2 == 'LotteryObject'){
        return (resolve) => require([`@/views/business/lottery/LotteryObject`], resolve)
      }else if(lastPath2 == 'LotteryDrawSet'){
        return (resolve) => require([`@/views/business/lottery/LotteryDrawSet`], resolve)
      }else if(lastPath2 == 'LotteryDraw'){
        return (resolve) => require([`@/views/business/lottery/LotteryDraw`], resolve)
      }else if(lastPath2 == 'LotteryResult'){
        return (resolve) => require([`@/views/business/lottery/LotteryResult`], resolve)
      }else if(lastPath2 == 'LotteryNeedRuleSet'){
        return (resolve) => require([`@/views/business/lottery/LotteryNeedRuleSet`], resolve)
      }else if(lastPath2 == 'LotteryNeedRule'){
        return (resolve) => require([`@/views/business/lottery/LotteryNeedRule`], resolve)
      }else if(lastPath2 == 'LotteryWinRuleSet'){
        return (resolve) => require([`@/views/business/lottery/LotteryWinRuleSet`], resolve)
      }else if(lastPath2 == 'LotteryWinRule'){
        return (resolve) => require([`@/views/business/lottery/LotteryWinRule`], resolve)
      }else{
        return (resolve) => require([`@/views/business/lottery/${lastPath2}`], resolve)
      }
    }else if(path.includes("business/mall/")){
      if(lastPath2 == 'index'){
        return (resolve) => require([`@/views/business/mall/index`], resolve)
      }else if(lastPath2 == 'catalog/index'){
        return (resolve) => require([`@/views/business/mall/catalog/index`], resolve)
      }else{
        return (resolve) => require([`@/views/business/mall/${lastPath2}`], resolve)
      }
    }else if(path.includes("business/market/")){
      return (resolve) => require([`@/views/business/market/${lastPath2}`], resolve)
    }else if(path.includes("business/notice/")){
      return (resolve) => require([`@/views/business/notice/${lastPath2}`], resolve)
    }else if(path.includes("business/orderform/")){
      if(lastPath2 == 'selectOrder'){
        return (resolve) => require([`@/views/business/orderform/selectOrder`], resolve)
      }else if(lastPath2 == 'selectDetail'){
        return (resolve) => require([`@/views/business/orderform/selectDetail`], resolve)
      }else if(lastPath2 == 'index'){
        return (resolve) => require([`@/views/business/orderform/index`], resolve)
      }else if(lastPath2 == 'detail'){
        return (resolve) => require([`@/views/business/orderform/detail`], resolve)
      }else if(lastPath2 == 'orderView'){
        return (resolve) => require([`@/views/business/orderform/orderView`], resolve)
      }else{
        return (resolve) => require([`@/views/business/orderform/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/pay/")){
      if(lastPath2 == 'payReturn'){
        return (resolve) => require([`@/views/business/pay/payReturn`], resolve)
      }else if(lastPath2 == 'SingleItemRefund'){
        return (resolve) => require([`@/views/business/pay/SingleItemRefund`], resolve)
      }else if(lastPath2 == 'payRefund'){
        return (resolve) => require([`@/views/business/pay/payRefund`], resolve)
      }else if(lastPath2 == 'index'){
        return (resolve) => require([`@/views/business/pay/index`], resolve)
      }else if(lastPath2 == 'rechargePayment'){
        return (resolve) => require([`@/views/business/pay/rechargePayment`], resolve)
      }else if(lastPath2 == 'AdapayBank'){
        return (resolve) => require([`@/views/business/pay/AdapayBank`], resolve)
      }else if(lastPath2 == 'AdapayAccountInfo'){
        return (resolve) => require([`@/views/business/pay/AdapayAccountInfo`], resolve)
      }else if(lastPath2 == 'AdapaySettleAccount'){
        return (resolve) => require([`@/views/business/pay/AdapaySettleAccount`], resolve)
      }else if(lastPath2 == 'AdapayMerchant'){
        return (resolve) => require([`@/views/business/pay/AdapayMerchant`], resolve)
      }else if(lastPath2 == 'AdapayCorpmember'){
        return (resolve) => require([`@/views/business/pay/AdapayCorpmember`], resolve)
      }else if(lastPath2 == 'AdapayCorpmemberDivSettle'){
        return (resolve) => require([`@/views/business/pay/AdapayCorpmemberDivSettle`], resolve)
      }else if(lastPath2 == 'AdapayCorpmemberDivPay'){
        return (resolve) => require([`@/views/business/pay/AdapayCorpmemberDivPay`], resolve)
      }else if(lastPath2 == 'AdapayPaymentConfirm'){
        return (resolve) => require([`@/views/business/pay/AdapayPaymentConfirm`], resolve)
      }else if(lastPath2 == 'AdapayPayment'){
        return (resolve) => require([`@/views/business/pay/AdapayPayment`], resolve)
      }else if(lastPath2 == 'AdapayPaymentOrder'){
        return (resolve) => require([`@/views/business/pay/AdapayPaymentOrder`], resolve)
      }else if(lastPath2 == 'AdapayMember'){
        return (resolve) => require([`@/views/business/pay/AdapayMember`], resolve)
      }else if(lastPath2 == 'withdrawal/AdapayCorpmember'){
        return (resolve) => require([`@/views/business/pay/withdrawal/AdapayCorpmember`], resolve)
      }else if(lastPath2 == 'withdrawal/AdapayCorpmemberDivSettle'){
        return (resolve) => require([`@/views/business/pay/withdrawal/AdapayCorpmemberDivSettle`], resolve)
      }else if(lastPath2 == 'withdrawal/AdapayCorpmemberDivPay'){
        return (resolve) => require([`@/views/business/pay/withdrawal/AdapayCorpmemberDivPay`], resolve)
      }else if(lastPath2 == 'withdrawal/AdapayCorpmemberDivPayAudit'){
        return (resolve) => require([`@/views/business/pay/withdrawal/AdapayCorpmemberDivPayAudit`], resolve)
      }else if(lastPath2 == 'withdrawal/OrderBrokeragePay'){
        return (resolve) => require([`@/views/business/pay/withdrawal/OrderBrokeragePay`], resolve)
      }else{
        return (resolve) => require([`@/views/business/pay/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/person/")){
      return (resolve) => require([`@/views/business/person/${lastPath2}`], resolve)
    }else if(path.includes("business/sale/")){
      return (resolve) => require([`@/views/business/sale/${lastPath2}`], resolve)
    }else if(path.includes("business/sales/")){
      if(lastPath2 == 'SalesTeamMember'){
        return (resolve) => require([`@/views/business/sales/SalesTeamMember`], resolve)
      }else if(lastPath2 == 'SalesTeam'){
        return (resolve) => require([`@/views/business/sales/SalesTeam`], resolve)
      }else if(lastPath2 == 'SalesBrokerageGeneralRule'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageGeneralRule`], resolve)
      }else if(lastPath2 == 'SalesBrokerageSpecificRule'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageSpecificRule`], resolve)
      }else if(lastPath2 == 'SalesBrokerageOrderGoodsRecord'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageOrderGoodsRecord`], resolve)
      }else if(lastPath2 == 'SalesBrokerageOrderBrokerGoodsRecord'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageOrderBrokerGoodsRecord`], resolve)
      }else if(lastPath2 == 'SalesTeamMemberAudit'){
        return (resolve) => require([`@/views/business/sales/SalesTeamMemberAudit`], resolve)
      }else if(lastPath2 == 'SalesTeamMemberManage'){
        return (resolve) => require([`@/views/business/sales/SalesTeamMemberManage`], resolve)
      }else if(lastPath2 == 'SalesTeamMemberRegionAudit'){
        return (resolve) => require([`@/views/business/sales/SalesTeamMemberRegionAudit`], resolve)
      }else if(lastPath2 == 'SalesBrokerageOrderRecord'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageOrderRecord`], resolve)
      }else if(lastPath2 == 'SalesBrokerageWithdraw'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageWithdraw`], resolve)
      }else if(lastPath2 == 'WithdrawAudit'){
        return (resolve) => require([`@/views/business/sales/WithdrawAudit`], resolve)
      }else if(lastPath2 == 'SalesBrokerageOrderBrokerRecord'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageOrderBrokerRecord`], resolve)
      }else if(lastPath2 == 'salesBrokerageWithdrawDetail'){
        return (resolve) => require([`@/views/business/sales/salesBrokerageWithdrawDetail`], resolve)
      }else if(lastPath2 == 'GeneralRebateRule'){
        return (resolve) => require([`@/views/business/sales/GeneralRebateRule`], resolve)
      }else if(lastPath2 == 'SalesBrokerageSpecificRuleQuery'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageSpecificRuleQuery`], resolve)
      }else if(lastPath2 == 'SalesBrokerageForbitRuleQuery'){
        return (resolve) => require([`@/views/business/sales/SalesBrokerageForbitRuleQuery`], resolve)
      }else if(lastPath2 == 'SalesTeamQuery'){
        return (resolve) => require([`@/views/business/sales/SalesTeamQuery`], resolve)
      }else if(lastPath2 == 'SalesTeamLeaderQuery'){
        return (resolve) => require([`@/views/business/sales/SalesTeamLeaderQuery`], resolve)
      }else{
        return (resolve) => require([`@/views/business/sales/${lastPath2}`], resolve)
      }
    }else if(path.includes("business/share/")){
      return (resolve) => require([`@/views/business/share/${lastPath2}`], resolve)
    }else if(path.includes("business/shop/")){
      if(lastPath2 == 'index'){
        return (resolve) => require([`@/views/business/shop/index`], resolve)
      }else if(lastPath2 == 'shopSelect'){
        return (resolve) => require([`@/views/business/shop/shopSelect`], resolve)
      }else if(lastPath2 == 'shopFirstAudit'){
        return (resolve) => require([`@/views/business/shop/shopFirstAudit`], resolve)
      }else if(lastPath2 == 'shopLastAudit'){
        return (resolve) => require([`@/views/business/shop/shopLastAudit`], resolve)
      }else if(lastPath2 == 'shopView'){
        return (resolve) => require([`@/views/business/shop/shopView`], resolve)
      }else{
        return (resolve) => require([`@/views/business/shop/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/shoppingcart/")){
      return (resolve) => require([`@/views/business/shoppingcart/${lastPath2}`], resolve)
    }else if(path.includes("business/stock/")){
      return (resolve) => require([`@/views/business/stock/${lastPath2}`], resolve)
    }else if(path.includes("business/wechat/")){
      return (resolve) => require([`@/views/business/wechat/${lastPath2}`], resolve)
    }else if(path.includes("business/yangfan/")){
      if(lastPath2 == 'WillSendRecord'){
        return (resolve) => require([`@/views/business/yangfan/WillSendRecord`], resolve)
      }else if(lastPath2 == 'SentOutRecord'){
        return (resolve) => require([`@/views/business/yangfan/SentOutRecord`], resolve)
      }else{
        return (resolve) => require([`@/views/business/yangfan/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/zsyf/")){
      if(lastPath2 == 'Prescription'){
        return (resolve) => require([`@/views/business/zsyf/Prescription`], resolve)
      }else if(lastPath2 == 'applyMaterials'){
        return (resolve) => require([`@/views/business/zsyf/applyMaterials`], resolve)
      }else if(lastPath2 == 'openingInformation'){
        return (resolve) => require([`@/views/business/zsyf/openingInformation`], resolve)
      }else if(lastPath2 == 'reviewerInformation'){
        return (resolve) => require([`@/views/business/zsyf/reviewerInformation`], resolve)
      }else if(lastPath2 == 'PrescriptionOpenRecord'){
        return (resolve) => require([`@/views/business/zsyf/PrescriptionOpenRecord`], resolve)
      }else if(lastPath2 == 'PrescriptionAuditRecord'){
        return (resolve) => require([`@/views/business/zsyf/PrescriptionAuditRecord`], resolve)
      }else if(lastPath2 == 'PrescriptionClinicRecord'){
        return (resolve) => require([`@/views/business/zsyf/PrescriptionClinicRecord`], resolve)
      }else if(lastPath2 == 'PrescriptionApplyRecord'){
        return (resolve) => require([`@/views/business/zsyf/PrescriptionApplyRecord`], resolve)
      }else{
        return (resolve) => require([`@/views/business/zsyf/${lastPath2}`], resolve)
      }
      
    }else if(path.includes("business/erp/")){
      if(lastPath2 == 'orderformSyncNotice'){
        return (resolve) => require([`@/views/business/erp/orderformSyncNotice`], resolve)
      }else if(lastPath2 == 'orderformSyncNoticeLog'){
        return (resolve) => require([`@/views/business/erp/orderformSyncNoticeLog`], resolve)
      }else if(lastPath2 == 'manualNotification'){
        return (resolve) => require([`@/views/business/erp/manualNotification`], resolve)
      }else{
        return (resolve) => require([`@/views/business/erp/${lastPath2}`], resolve)
      }
    }else{
      return (resolve) => require([`@/views/business/${lastPath}`], resolve)
    }
  }else if(path.includes('components/')){
    return (resolve) => require([`@/views/components/${lastPath}`], resolve)
  }else if(path.includes('content/')){
    return (resolve) => require([`@/views/content/${lastPath}`], resolve)
  }else if(path.includes('dashboard/')){
    return (resolve) => require([`@/views/dashboard/${lastPath}`], resolve)
  }else if(path.includes('error/')){
    return (resolve) => require([`@/views/error/${lastPath}`], resolve)
  }else if(path.includes('mall/')){
    return (resolve) => require([`@/views/mall/${lastPath}`], resolve)
  }else if(path.includes('monitor/')){
    return (resolve) => require([`@/views/monitor/${lastPath}`], resolve)
  }else if(path.includes('public/')){
    let lastPathList2 = lastPath.split('/')
    lastPathList2.splice(0,1)
    let lastPath2 = lastPathList2.join('/')
    if(path.includes("public/activity/")){
      return (resolve) => require([`@/views/public/activity/${lastPath2}`], resolve)
    }else if(path.includes("public/agent/")){
      return (resolve) => require([`@/views/public/agent/${lastPath2}`], resolve)
    }else if(path.includes("public/application/")){
      return (resolve) => require([`@/views/public/application/${lastPath2}`], resolve)
    }else if(path.includes("public/corp/")){
      return (resolve) => require([`@/views/public/corp/${lastPath2}`], resolve)
    }else if(path.includes("public/coupon/")){
      return (resolve) => require([`@/views/public/coupon/${lastPath2}`], resolve)
    }else if(path.includes("public/goods/")){
      if(lastPath2 == 'goodsPriceSet'){
        return (resolve) => require([`@/views/public/goods/goodsPriceSet`], resolve)
      }else{
        return (resolve) => require([`@/views/public/goods/${lastPath2}`], resolve)
      }
    }else if(path.includes("public/hospital/")){
      return (resolve) => require([`@/views/public/hospital/${lastPath2}`], resolve)
    }else if(path.includes("public/mallSelect/")){
      return (resolve) => require([`@/views/public/mallSelect/${lastPath2}`], resolve)
    }else if(path.includes("public/manage/")){
      return (resolve) => require([`@/views/public/manage/${lastPath2}`], resolve)
    }else if(path.includes("public/orderform/")){
      return (resolve) => require([`@/views/public/orderform/${lastPath2}`], resolve)
    }else if(path.includes("public/pay/")){
      return (resolve) => require([`@/views/public/pay/${lastPath2}`], resolve)
    }else if(path.includes("public/shop/")){
      return (resolve) => require([`@/views/public/shop/${lastPath2}`], resolve)
    }else if(path.includes("public/sysAuth/")){
      return (resolve) => require([`@/views/public/sysAuth/${lastPath2}`], resolve)
    }else if(path.includes("public/user/")){
      return (resolve) => require([`@/views/public/user/${lastPath2}`], resolve)
    }else if(path.includes("public/sales/")){
      return (resolve) => require([`@/views/public/sales/${lastPath2}`], resolve)
    }else if(path.includes("public/groupBooking/")){
      return (resolve) => require([`@/views/public/groupBooking/${lastPath2}`], resolve)
    }else if(path.includes("public/erp/")){
      if(lastPath2 == 'orderformSyncNotice'){
        return (resolve) => require([`@/views/public/erp/orderformSyncNotice`], resolve)
      }else if(lastPath2 == 'orderformSyncNoticeLog'){
        return (resolve) => require([`@/views/public/erp/orderformSyncNoticeLog`], resolve)
      }else{
        return (resolve) => require([`@/views/public/erp/${lastPath2}`], resolve)
      }
    }
  }else if(path.includes('redpacket/')){
    return (resolve) => require([`@/views/redpacket/${lastPath}`], resolve)
  }else if(path.includes('system/')){
    return (resolve) => require([`@/views/system/${lastPath}`], resolve)
  }else if(path.includes('tool/')){
    return (resolve) => require([`@/views/tool/${lastPath}`], resolve)
  }else if(path.includes('wap/')){
    return (resolve) => require([`@/views/wap/${lastPath}`], resolve)
  }else if(path.includes('helperdoc/')){
    return (resolve) => require([`@/views/helperdoc/${lastPath}`], resolve)
  }else{
    return (resolve) => require([`@/views/${path}`], resolve)
  }
}

export default new Router({
  base: process.env.VUE_APP_ROUTER_PREFIX,
  mode: 'history', // 去掉url中的#
  // scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
