<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapGetters(["token"]),
  },
  watch: {
    token: {
      handler: function (val) {
        if (val) {
          this.signalr.start();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style>
  /* 设置表头样式*/ 
  .station-search .table-head {
    font-size: 14px !important;
  }
  .station-search .el-table .caret-wrapper{
    position:absolute;
    top:2px;
    right:0;
  }
  .station-search .el-table .cell, .el-table th div{
    padding:0!important;
  }
  .station-search .el-table tr td .cell{
    padding:5px 2px !important;
  }
  .station-search .el-table .cell,
  .station-search .el-table th div,
  .station-search .el-table--border td:first-child .cell,
  .station-search .el-table--border th:first-child .cell{
    padding-left:0 !important;
  }
  /* 设置表头样式*/ 
</style>
