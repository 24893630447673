var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "editor", staticClass: "editor", style: _vm.styles }),
    _c("input", {
      staticStyle: { display: "none" },
      attrs: {
        id: "file",
        type: "file",
        accept: "image/png, image/gif, image/jpeg, image/bmp, image/x-icon",
      },
      on: { change: _vm.onFileChange },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }