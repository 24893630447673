import request from '@/utils/request'

/**
* 店铺表分页查询
* @param {查询条件} data
*/
export function listShop(query) {
  return request({
    url: 'business/Shop/list',
    method: 'get',
    params: query,
  })
}

/**
* 店铺表分页查询
* @param {查询条件} data
*/
export function viewListShop(query) {
  return request({
    url: 'business/Shop/viewlist',
    method: 'get',
    params: query,
  })
}

/**
* 店铺表分页查询
* @param {查询条件} data
*/
export function selectListShop(query) {
  return request({
    url: 'client/Shop/selectlist',
    method: 'get',
    params: query,
  })
}

/**
* 新增店铺表
* @param data
*/
export function addShop(data) {
  return request({
    url: 'business/Shop',
    method: 'post',
    data: data,
  })
}

/**
* 修改店铺表
* @param data
*/
export function updateShop(data) {
  return request({
    url: 'business/Shop',
    method: 'PUT',
    data: data,
  })
}

/**
* 获取店铺表详情
* @param {Id}
*/
export function getShop(id) {
  return request({
    url: 'business/Shop/' + id,
    method: 'get'
  })
}

/**
* 获取店铺二维码
* @param {Id}
*/
export function getQrcode(query) {
  return request({
    url: '/business/Shop/GetWxaCode',
    method: 'get',
    params: query,
    responseType:'blob'
  })
}

/**
* 删除店铺表
* @param {主键} pid
*/
export function delShop(pid) {
  return request({
    url: 'business/Shop/' + pid,
    method: 'delete'
  })
}

// 导出店铺表
export function exportShop(query) {
  return request({
    url: 'business/Shop/export',
    method: 'get',
    params: query
  })
}

